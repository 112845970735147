import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faHouse, faAddressCard, faGears } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-scroll';
import 'aos/dist/aos.css';
import AOS from 'aos';


const Home = () => {

    useEffect(() => {
        AOS.init({
            duration: 800,
            once: true
        });
    }, []);

    const whatsappLink = "https://wa.me/919591550901";


    return (
        <div>
            <div id='home'>
                <header className="header sticky">
                    <div className="topbar">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-5 col-12">
                                    <ul className="top-link">

                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-7 col-12">
                                    <ul className="top-contact">
                                        <li><a href={whatsappLink}><i className="fa fa-whatsapp"></i>WhatsApp</a></li>
                                        <li><a href="tel:+919591550901"><i className="fa fa-phone"></i>95915 50901</a></li>
                                        <li><i className="fa fa-envelope"></i><a href="mailto:support@yourmail.com">admin@hlmexplore.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-inner">
                        <div className="container">
                            <div className="inner">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-12">
                                        <div className="logo">
                                            <a href="index.html"><img src="img/hlmlogo.png" alt="#" style={{ marginBottom: '10px' }}></img></a>
                                        </div>
                                        <div className="mobile-nav">
                                            <div className="slicknav_menu">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-9 col-12">
                                        <div className="main-menu">
                                            <nav className="navigation">
                                                <ul className="nav menu">
                                                    <li><Link to="home" smooth={true} duration={500} offset={-70} className="about-link"><FontAwesomeIcon icon={faHouse} /> Home</Link></li>
                                                    <li><Link to="aboutus" smooth={true} duration={500} offset={-70} className="about-link"><FontAwesomeIcon icon={faAddressCard} /> About Us</Link></li>
                                                    <li><Link to="services" smooth={true} duration={500} offset={-70} className="about-link"><FontAwesomeIcon icon={faGears} /> Services</Link></li>
                                                    <li><Link to="contact" smooth={true} duration={500} offset={-70} className="about-link"><FontAwesomeIcon icon={faPhone} /> Contact Us</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                        {/*/ End Main Menu */}
                                    </div>
                                    <div className="col-lg-2 col-12">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*/ End Header Inner */}
                </header>
            </div>

            {/* Slider Area */}
            <section className="slider">
                <div className="hero-slider">
                    {/* Start Single Slider */}
                    <div className="single-slider" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1523906834658-6e24ef2386f9?q=80&w=1883&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="text">
                                        <h1>Embark on Boundless<span> Adventures with </span>  HLM Explore - Your Passport to  <span>Unforgettable Journeys!</span></h1>
                                        <p style={{ fontWeight: 'bold', color: 'white' }}>Explore the world with confidence and curiosity through HLM Explore, your premier travel agency for unforgettable experiences.</p>
                                        <div className="button">
                                            <a href={whatsappLink} className='btn'><i className="fa fa-whatsapp"></i>WhatsApp</a>
                                            <a href="tel:95915 50901" className="btn primary">call us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Slider */}
                    {/* Start Single Slider */}
                    <div className="single-slider" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1503220317375-aaad61436b1b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="text">
                                        <h1>Embark on Boundless<span> Adventures with </span>  HLM Explore - Your Passport to  <span>Unforgettable Journeys!</span></h1>
                                        <p style={{ fontWeight: 'bold', color: 'white' }}>Explore the world with confidence and curiosity through HLM Explore, your premier travel agency for unforgettable experiences.</p>
                                        <div className="button">
                                            <a href={whatsappLink} className='btn'><i className="fa fa-whatsapp"></i>WhatsApp</a>
                                            <a href="tel:95915 50901" className="btn primary">call us</a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Slider */}
                    {/* Start Single Slider */}
                    <div className="single-slider" style={{ backgroundImage: `url('https://images.unsplash.com/photo-1539635278303-d4002c07eae3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="text">
                                        <h1>Embark on Boundless<span> Adventures with </span>  HLM Explore - Your Passport to  <span>Unforgettable Journeys!</span></h1>
                                        <p style={{ fontWeight: 'bold', color: 'white' }}>Explore the world with confidence and curiosity through HLM Explore, your premier travel agency for unforgettable experiences.</p>
                                        <div className="button">
                                            <a href={whatsappLink} className='btn'><i className="fa fa-whatsapp"></i>WhatsApp</a>
                                            <a href="tel:95915 50901" className="btn primary">call us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Slider */}
                </div>
            </section>
            {/*/ End Slider Area */}
            {/* Start Schedule Area */}

            <div>



                <div id='aboutus' style={{ paddingTop: '130px' }}>
                    {/* Breadcrumbs */}
                    <div className="breadcrumbs overlay">
                        <div className="container">
                            <div className="bread-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <h2>About us</h2>
                                        <ul className="bread-list">
                                            <li><a href="index.html">Home</a></li>
                                            <li><i className="icofont-simple-right"></i></li>
                                            <li className="active">About us</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrumbs */}

                    {/* Start aboutus Details Area */}
                    <section className="pf-details section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="inner-content">
                                        <div className="image-slider">
                                            <div className="pf-details-slider">
                                                <img src="https://images.unsplash.com/photo-1536607961765-592e80bcc19e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="#"/>
                                                <img src="https://images.unsplash.com/photo-1708973908941-e043bdee8a88?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="#" />
                                                <img src="https://images.unsplash.com/photo-1708806016593-e6be239f9d83?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="#" />
                                            </div>
                                        </div>
                                        {/* <div className="date">

                                        </div> */}
                                        <div className="body-text">
                                            <h3>About HLM Explore:</h3>
                                            <p>
                                                Welcome to HLM Explore, your premier destination for unforgettable travel experiences. At HLM Explore,
                                                we are more than just a travel and tours company - we are your trusted guides to discovering the world's wonders.
                                                With over a decade of expertise in Human Resource Management (HRM) visa services, we bring a wealth of knowledge and professionalism to every journey we undertake.</p>
                                            <h3>Our Expertise:</h3>
                                            <p>

                                                With 10 years of experience in HRM visa services, we understand the intricacies and challenges of international travel.
                                                Our team is adept at navigating the complexities of visa applications, ensuring a smooth and hassle-free process for our clients.
                                                Whether you're embarking on a solo adventure, a romantic getaway, or a family vacation,
                                                our expertise in HRM visas ensures that your travel plans remain stress-free.</p>
                                            <h3>Unparalleled Service:</h3>
                                            <p>

                                                At HLM Explore, we are committed to providing unparalleled service to our clients. Our dedicated team works tirelessly to curate tailor-made
                                                travel experiences that cater to your preferences and desires.From arranging accommodations to planning sightseeing tours, we handle every aspect of your journey with precision and care.
                                                With HLM Explore, you can rest assured that your travel dreams are in capable hands.</p>
                                            <div className="share">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* End aboutus Details Area */}
            </div>
            {/* start of services Area */}

            <div id='services'>
                <div className="container mt-5">

                    <h1 className="text-center mb-4 services-heading">OUR SERVICES</h1>
                    <div className="line" data-aos="fade-right"></div>

                    <div>
                        <div className="row card-body2">
                            <div className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="50">
                                <Link to="/tour-packages" className="card-link" style={{ textDecoration: 'none' }}>
                                    <div className="card" id="visa">
                                        <div className="card-body with-shadow">
                                            <h5 className="card-title">Visa</h5>
                                            <p className="card-text">Let us handle your visa processing hassle-free.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="100">
                                <Link to="/air-tickets" className="card-link" style={{ textDecoration: 'none' }}>
                                    <div className="card" id="air-tickets">
                                        <div className="card-body with-shadow">
                                            <h5 className="card-title">Air Tickets</h5>
                                            <p className="card-text">Book your flight tickets with <br /> ease and convenience.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="150">
                                <Link to="/travel-insurance" className="card-link" style={{ textDecoration: 'none' }}>
                                    <div className="card" id="travel-insurance">
                                        <div className="card-body with-shadow">
                                            <h5 className="card-title">Travel Insurance</h5>
                                            <p className="card-text">Protect your journey with our comprehensive travel insurance.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="200">
                                <Link to="/forex" className="card-link" style={{ textDecoration: 'none' }}>
                                    <div className="card" id="forex">
                                        <div className="card-body with-shadow">
                                            <h5 className="card-title">Forex (Currency and Global Card)</h5>
                                            <p className="card-text">Exchange currency and get global cards for your travel.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="250">
                                <Link to="/cabs-and-spare-driver" className="card-link" style={{ textDecoration: 'none' }}>
                                    <div className="card" id="cabs-and-spare-driver" >
                                        <div className="card-body with-shadow">
                                            <h5 className="card-title">Cabs and Spare Driver</h5>
                                            <p className="card-text">Hire cabs or drivers for your <br />convenience.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="300">
                                <Link to="/individual-corporate-day-outing" className="card-link" style={{ textDecoration: 'none' }}>
                                    <div className="card" id="individual-corporate-day-outing">
                                        <div className="card-body with-shadow">
                                            <h5 className="card-title">Individual/Corporate Day Outing</h5>
                                            <p className="card-text">Plan your memorable day outing <br />
                                                with us today.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 mb-4" data-aos="fade-up" data-aos-delay="350">
                                <Link to="/hotel-resort-booking" className="card-link" style={{ textDecoration: 'none' }}>
                                    <div className="card" id="hotel-resort-booking">
                                        <div className="card-body with-shadow">
                                            <h5 className="card-title">Hotel/Resort Booking</h5>
                                            <p className="card-text">Find the perfect accommodation for your trip.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* end of services area */}

            <div id='contact' style={{ paddingTop: '30px' }}> {/* Breadcrumbs */}
                <div className="breadcrumbs overlay">
                    <div className="container">
                        <div className="bread-inner">
                            <div className="row">
                                <div className="col-12">
                                    <h2>Contact Us</h2>
                                    <ul className="bread-list">
                                        <li><a href="/">Home</a></li>
                                        <li><i className="icofont-simple-right"></i></li>
                                        <li className="active">Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrumbs */}

                {/* Start Contact Us */}
                <section className="contact-us section">
                    <div className="container">
                        <div className="inner">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="contact-us-left">
                                        {/* Start Google-map */}
                                        <div id="myMap">
                                            <iframe
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.5315274051304!2d77.62455257320897!3d12.93780341562214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1443a8764a8d%3A0x6fd6295e4c4ab741!2sOne%20Co.Work!5e0!3m2!1sen!2sin!4v1709231099777!5m2!1sen!2sin"
                                                width="600"
                                                height="450"
                                                style={{ border: 0 }}
                                                allowFullScreen=""
                                                loading="lazy"
                                                referrerPolicy="no-referrer-when-downgrade"
                                            />
                                        </div>
                                        {/*/End Google-map */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="contact-us-form">
                                        <h2>Contact With Us</h2>
                                        <p>If you have any questions please fell free to contact with us.</p>
                                        {/* Form */}
                                        <form className="form" method="post" action="mail/mail.php">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="name" placeholder="Name" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="email" name="email" placeholder="Email" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="phone" placeholder="Phone" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="subject" placeholder="Subject" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <textarea name="message" placeholder="Your Message" required=""></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group login-btn">
                                                        <button className="btn" type="submit">Send</button>
                                                    </div>
                                                    <div className="checkbox">
                                                        <label className="checkbox-inline" htmlFor="2"><input name="news" id="2" type="checkbox" />Do you want to subscribe our Newsletter ?</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        {/*/ End Form */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="contact-info">
                            <div className="row">
                                {/* single-info */}
                                <div className="col-lg-4 col-12">
                                    <div className="single-info">
                                        <i className="icofont icofont-ui-call"></i>
                                        <div className="content">
                                            <h3><a href="tel:+919591550901">95915 50901</a>1</h3>
                                            <p><a href="mailto:support@yourmail.com">admin@hlmexplore.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                {/*/End single-info */}
                                {/* single-info */}
                                <div className="col-lg-4 col-12">
                                    <div className="single-info">
                                        <i className="icofont-google-map"></i>
                                        <div className="content">
                                            <h3>SJR,2/3,2nd floor,
                                            </h3>
                                            <p>
                                                Ejipura, Koramangala,
                                                Bengaluru, Karnataka- 560095</p>
                                        </div>
                                    </div>
                                </div>
                                {/*/End single-info */}
                                {/* single-info */}
                                <div className="col-lg-4 col-12">
                                    <div className="single-info">
                                        <i className="icofont icofont-wall-clock"></i>
                                        <div className="content">
                                            <h3>Mon - Sat: 9am - 6pm</h3>
                                            <p>Sunday Closed</p>
                                        </div>
                                    </div>
                                </div>
                                {/*/End single-info */}
                            </div>
                        </div>
                    </div>
                </section>
                {/*/ End Contact Us */}</div>
            <div id='footer'>
                <footer id="footer" className="footer">
                    {/* Footer Top */}
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="single-footer">
                                        <h2>About Us</h2>
                                        <p>  we are your trusted guides to discovering the world's wonders. With over a decade of expertise in Human Resource Management (HRM) visa services, we bring a wealth of knowledge and professionalism to every journey we undertake.</p>
                                        {/* Social */}


                                        {/* End Social */}
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="single-footer f-link">
                                        <h2>Quick Links</h2>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <ul>
                                                
                                                     <li><Link to="home" smooth={true} duration={500} offset={-70} className="fa fa-caret-right"><i className="fa fa-caret-right" aria-hidden="true"></i> Home</Link></li>
                                                     <li><Link to="aboutus" smooth={true} duration={500} offset={-70} className="fa fa-caret-right"><i className="fa fa-caret-right" aria-hidden="true"></i> About us</Link></li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <ul>
                                                <li><Link to="services" smooth={true} duration={500} offset={-70} className="fa fa-caret-right"><i className="fa fa-caret-right" aria-hidden="true"></i> Services</Link></li>
                                                <li><Link to="contact" smooth={true} duration={500} offset={-70} className="fa fa-caret-right"><i className="fa fa-caret-right" aria-hidden="true"></i> Contact us</Link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="single-footer">
                                        <h2>Open Hours</h2>
                                        <p>Office Address :
                                            SJR, 2/3, 2nd floor, 80 feet road,
                                            Above KFC , sony world junction ,
                                            Ejipura, Koramangala,
                                            Bengaluru, Karnataka- 560095</p>
                                        <ul className="time-sidual">
                                            <li className="day">Monday - Saturday <span>09.00AM-06.00PM</span></li>
                                            <li className="day">Sunday <span>Closed</span></li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="single-footer">
                                        <h2>Newsletter</h2>
                                        <p>subscribe to our newsletter to get all our news in your inbox..</p>
                                        <form action="mail/mail.php" method="get" target="_blank" className="newsletter-inner">
                                            <input name="email" placeholder="Email Address" className="common-input" onFocus={(e) => e.target.placeholder = ''} onBlur={(e) => e.target.placeholder = 'Your email address'} required type="email" />
                                            <button className="button"><i className="icofont icofont-paper-plane"></i></button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*/ End Footer Top */}
                    {/* Copyright */}
                    <div className="copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-12">
                                    <div className="copyright-content">
                                        <p>© Copyright 2024 |  Design and developed by <a href="https://www.hrmitsolutions.com/" target="_blank">HRM IT SOLUTIONS</a> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>
            </div>
        </div>
    );
}

export default Home;
